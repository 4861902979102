import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import throttle from "lodash.throttle";

const Header = () => {
  const [menuOpened, setMenuOpened] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const toggleMenu = () => {
    setMenuOpened(!menuOpened);
  };

  const handleNavClick = (e) => {
    toggleMenu();
  };

  const handleScroll = () => {
    const windowTop = window.scrollY + 1;
    setIsSticky(windowTop > 150);
  };

  useEffect(() => {
    const throttleScroll = throttle(() => handleScroll(), 200);
    window.addEventListener("scroll", throttleScroll);
    return () => {
      window.removeEventListener("scroll", throttleScroll);
    };
  });

  return (
    <header
      className={`body-font z-50 w-full  ${
        isSticky
          ? "fixed top-0 animate__animated animate__fadeInDown bg-indigo-600"
          : "absolute top-0"
      }`}
    >
      <nav className="container mx-auto px-5 py-6 flex flex-wrap flex-row items-center justify-between">
        <Link to="/" className="w-48">
          <h2 className="text-2xl text-gray-100 hover:text-gray-300 font-semibold">
            Cloud House
          </h2>
        </Link>
        <ul
          className={`list-none flex flex-col sm:flex-row flex-wrap items-center justify-center sm:justify-start sm:visible
            fixed top-0 left-0 sm:static w-full sm:w-auto h-screen sm:h-full text-2xl sm:text-sm lg:text-base z-50 ${
              menuOpened ? "bg-indigo-600 sm:bg-transparent" : "menu-closed"
            }`}
        >
          <li className="p-6 sm:p-2 md:p-3 sm:mr-3 lg:mr-10">
            <Link
              to="/"
              className="uppercase text-gray-100 hover:text-gray-300 font-semibold"
              activeClassName="active"
              onClick={(e) => handleNavClick(e)}
            >
              Home
            </Link>
          </li>
          <li className="dropdown relative p-6 sm:p-2 md:p-3 sm:mr-3 lg:mr-10">
            <a
              className="uppercase text-gray-100 hover:text-gray-300 font-semibold"
              href="#"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Oferta
            </a>
            <div className="dropdown-menu">
              <Link
                onClick={() => handleNavClick()}
                to="/oferta/strony-www"
                className="dropdown-item"
              >
                Strony WWW
              </Link>
              <Link
                onClick={() => handleNavClick()}
                to="/oferta/e-commerce"
                className="dropdown-item"
              >
                E-commerce
              </Link>
              <Link
                onClick={() => handleNavClick()}
                to="/oferta/marketing-internetowy"
                className="dropdown-item"
              >
                Marketing internetowy
              </Link>
            </div>
          </li>
          <li className="p-6 sm:p-2 md:p-3 sm:mr-3 lg:mr-10">
            <Link
              to="/portfolio"
              className="uppercase text-gray-100 hover:text-gray-300 font-semibold"
              activeClassName="active"
              onClick={(e) => handleNavClick(e)}
            >
              Portfolio
            </Link>
          </li>
          <li className="p-6 sm:p-2 md:p-3 sm:mr-3 lg:mr-10">
            <Link
              to="/kontakt"
              className="uppercase text-gray-100 hover:text-gray-300 font-semibold"
              activeClassName="active"
              onClick={(e) => handleNavClick(e)}
            >
              Kontakt
            </Link>
          </li>
        </ul>
        <button
          id="menuBtn"
          className={`hamburger absolute right-3 sm:hidden focus:outline-none ${
            menuOpened ? "opened" : ""
          }`}
          type="button"
          onClick={() => toggleMenu()}
        >
          <span className="hamburger__top-bun"></span>
          <span className="hamburger__bottom-bun"></span>
        </button>
      </nav>
    </header>
  );
};

export default Header;

import React from "react";
import { Link } from "gatsby";
import config from "../../../config.json";
import Mail from "../ui/icons/Mail";
import Phone from "../ui/icons/Phone";
import Pin from "../ui/icons/Pin";

const Footer = () => {
  return (
    <footer id="contact" className="text-gray-100 body-font bg-black">
      <div className="container mx-auto px-5 py-12 md:py-20 flex flex-row flex-wrap justify-between">
        <h2 className="text-2xl font-bold mb-5">Cloud House</h2>
        <div className="flex flex-wrap flex-col sm:flex-row">
          <div className="px-3 sm:px-10 mb-5 lg:mb-0">
            <h2 className="title-font font-semibold tracking-widest text-sm mb-3">
              Regulaminy
            </h2>
            <Link to="/polityka-prywatnosci" className="text-gray-300">
              Polityka prywatności
            </Link>
          </div>
          <div className="px-3 sm:px-10 mb-5 lg:mb-0">
            <h2 className="title-font font-semibold tracking-widest text-sm mb-3">
              Kontakt
            </h2>
            <div className="flex items-center my-2">
              <Mail width="18px" fill="#fff" />
              <a href={`mailto:${config.email}`} className="text-gray-300 ml-2">
                {config.email}
              </a>
            </div>
            <div className="flex items-start my-2">
              <Phone width="18px" fill="#fff" />
              <div>
                <a
                  href={`tel:${config.phone}`}
                  className="text-gray-300 ml-2 block"
                >
                  {config.phone}
                </a>
              </div>
            </div>
            <div className="flex items-start my-2">
              <Pin width="18px" fill="#fff" />
              <span className="text-gray-300 ml-2">
                {config.address1} <br /> {config.address2}
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

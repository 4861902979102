import * as React from "react";

function Phone(props) {
  return (
    <svg x="0px" y="0px" viewBox="0 0 512 512" xmlSpace="preserve" {...props}>
      <path d="M366 0H146c-20.678 0-37.5 16.822-37.5 37.5v437c0 20.678 16.822 37.5 37.5 37.5h220c20.678 0 37.5-16.822 37.5-37.5v-437C403.5 16.822 386.678 0 366 0zm22.5 407H176a7.5 7.5 0 000 15h212.5v52.5c0 12.407-10.093 22.5-22.5 22.5H146c-12.407 0-22.5-10.093-22.5-22.5V422H146a7.5 7.5 0 000-15h-22.5V75h265v332zm0-347h-265V37.5c0-12.407 10.093-22.5 22.5-22.5h220c12.407 0 22.5 10.093 22.5 22.5V60z" />
      <path d="M286 30h-30a7.5 7.5 0 000 15h30a7.5 7.5 0 000-15zM256 437c-12.407 0-22.5 10.093-22.5 22.5S243.593 482 256 482s22.5-10.093 22.5-22.5S268.407 437 256 437zm0 30c-4.136 0-7.5-3.364-7.5-7.5s3.364-7.5 7.5-7.5 7.5 3.364 7.5 7.5-3.364 7.5-7.5 7.5z" />
      <circle cx={226} cy={37.5} r={7.5} />
    </svg>
  );
}

export default Phone;

import * as React from "react";

function Pin(props) {
  return (
    <svg x="0px" y="0px" viewBox="0 0 512 512" xmlSpace="preserve" {...props}>
      <path d="M256 0C152.612 0 68.5 84.112 68.5 187.5c0 38.607 11.653 75.694 33.673 107.215l147.654 214.044a7.5 7.5 0 0012.346 0l147.628-214.007C431.847 263.194 443.5 226.107 443.5 187.5 443.5 84.112 359.388 0 256 0zm141.48 286.197L256 491.292 114.495 286.161C94.218 257.135 83.5 223.019 83.5 187.5 83.5 92.383 160.883 15 256 15s172.5 77.383 172.5 172.5c0 35.519-10.718 69.635-31.02 98.697z" />
      <path d="M256 60c-70.304 0-127.5 57.196-127.5 127.5S185.696 315 256 315s127.5-57.196 127.5-127.5S326.304 60 256 60zm0 240c-62.033 0-112.5-50.467-112.5-112.5S193.967 75 256 75s112.5 50.467 112.5 112.5S318.033 300 256 300z" />
      <path d="M256.625 90.624c-53.762 0-97.5 43.738-97.5 97.5 0 20.398 6.235 39.935 18.03 56.499a7.5 7.5 0 0012.219-8.701c-9.976-14.008-15.249-30.536-15.249-47.797 0-45.491 37.009-82.5 82.5-82.5s82.5 37.009 82.5 82.5-37.009 82.5-82.5 82.5c-17.261 0-33.789-5.273-47.797-15.249a7.5 7.5 0 00-8.701 12.219c16.563 11.795 36.101 18.03 56.499 18.03 53.762 0 97.5-43.738 97.5-97.5s-43.74-97.501-97.501-97.501z" />
      <path d="M256.624 180.624c-4.136 0-7.5 3.365-7.5 7.5 0 4.136 3.364 7.5 7.5 7.5s7.5-3.364 7.5-7.5c0-4.135-3.365-7.5-7.5-7.5zM226.624 180.624c-4.136 0-7.5 3.365-7.5 7.5 0 4.136 3.364 7.5 7.5 7.5s7.5-3.364 7.5-7.5c0-4.135-3.365-7.5-7.5-7.5zM286.624 180.624c-4.136 0-7.5 3.365-7.5 7.5 0 4.136 3.364 7.5 7.5 7.5s7.5-3.364 7.5-7.5c0-4.135-3.365-7.5-7.5-7.5z" />
    </svg>
  );
}

export default Pin;
